<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-12">
        <b-row class="justify-content-between mb-2">
          <b-col
              cols="12"
              md="1"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1">Show</label>
            <Select
                @on-change="paginateChange"
                v-model="search.paginate"
                :clearable="false"
            >
            <Option :value="10"> 10</Option>
            <Option :value="30"> 30</Option>
            <Option :value="50"> 50</Option>
            <Option :value="80"> 80</Option>
            <Option :value="100"> 100</Option>
            </Select>
          </b-col>
          <b-col cols="12" md="4">
            <Input
                v-model="search.search_data"
                class="d-inline-block mr-1"
                placeholder="Search name..."
                type="text"
            />
          </b-col>
        </b-row>
        <b-row v-if="certificates.data.length">
          <b-col cols="4"
                 v-for="(certificate, index) in certificates.data"
                 :value="index"
          >
            <b-card>
              <b-card-body>
                <div>
                  <span><b>Certificate ID : </b>{{ certificate.certificate_id }}</span>
                </div>
                <div>
                  <span><b>Format Name : </b>{{ certificate.format_name }}</span>
                </div>
                <hr>

                <div class="scroll_overflow">
                  <p class="font-weight-bold" style="margin-bottom: 5px"
                     v-if="certificate.data"
                     v-for="(value, index) in certificate.data"
                     :key="index"
                  >
                    <b v-if="index != 'certificate_id'">{{
                        index.charAt(0).toUpperCase() + index.replace(/_/g, " ").slice(1)
                                                        }} :</b> {{ index != 'certificate_id' ? value : '' }}
                  </p>
                </div>
                <a class="btn btn-primary d-block mt-2"
                   :href="'https://certificate.creativeit.xyz/certificate?cid=' + certificate.certificate_id"
                   target="_blank">View</a>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-if="!certificates.data.length">
          <b-col class="text-center">
            <h3>Data not found</h3>
          </b-col>
        </b-row>

        <div class="d-flex justify-content-between align-items-center my-2">
                        <span class="text-muted"
                        >Showing {{ certificates.meta ? certificates.meta.from : 0 }} to
                          {{ certificates.meta ? certificates.meta.to : 0 }} of
                          {{ certificates.meta ? certificates.meta.total : 0 }} entries</span
                        >
          <pagination
              :data="certificates"
              @pagination-change-page="getResults"
              align="right"
              :show-disabled="true"
              :limit="5"
          ></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormCheckbox,
  BForm,
  BButton,
  BFormFile,
} from "bootstrap-vue";
import {HasError} from "vform/src/components/bootstrap5";
import _ from "lodash";

export default {
  data() {
    return {
      search: {
        search_data: "",
        paginate: 10,
        page: 1,
      },
      certificates: {}
    };
  },
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
    BFormFile,
  },
  mounted() {
    this.getResults();
  },
  methods: {
    getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const queryParams = this.buildQueryParamString(this.search);
      axios
          .get(`/app/certificate${queryParams}`)
          .then((res) => {
            this.certificates = res.data;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions.certificate;
    },
  },
  watch: {
    "search.search_data": function () {
      this.SearchData();
    },
    "search.paginate": function () {
      this.paginateChange();
    },
  },
};
</script>

<style scoped>
.scroll_overflow {
  height: 300px;
  overflow-y: scroll;
}

.scroll_overflow::-webkit-scrollbar {
  width: 6px;
  border-radius: 3px;
  background: #fff;
}

.scroll_overflow::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #7367f0;
}

.scroll_overflow:hover::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #7367f0;
}
</style>